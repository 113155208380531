import { h } from "preact"
import { FC } from "preact/compat"
import { useState } from "preact/hooks"
import Button from "@mui/joy/Button"
import Link from "@mui/joy/Link"
import Card from "@mui/joy/Card"
import Modal from "@mui/joy/Modal"
import ModalClose from "@mui/joy/ModalClose"
import ModalDialog from "@mui/joy/ModalDialog"
import Box from "@mui/joy/Box"
import Typography from "@mui/joy/Typography"

import Section from "../../Section"


interface Modality {
	title: string;
	description: string;
}

interface ModalityProps {
	modality: Modality;
}

const Modality: FC<ModalityProps> = ({ modality }) => {
	const { title, description } = modality
	const [expanded, setExpanded] = useState(false)

	const toggleExpanded = () => setExpanded(!expanded)

	return <Card variant="outlined" sx={{ position: "relative", display: "inline-flex", flexDirection: "column", flexGrow: "1", textAlign: "center", flexBasis: "300px", margin: "5px", justifyContent: "flex-start", maxHeight: "100%" }}>
		<Typography level='h4'>{title}</Typography>
		<Typography sx={{ flexGrow: "2", marginTop: "10px", overflow: "hidden" }}>{`${description.substring(0, 200)}...`}</Typography>
		<Button sx={{ flexGrow: "1", fontWeight: "xl", marginTop: "auto" }} variant="plain">See More Details</Button>
		<Link onClick={() => setExpanded(true)} overlay />
		<Modal open={expanded} onClose={toggleExpanded}>
			<ModalDialog size="lg" sx={{ maxHeight: "90%", padding: "20px", overflowY: "auto", width: "80%" }}>
				<ModalClose />
				<Typography level='h4'>{title}</Typography>
				<Typography sx={{ marginTop: 2, padding: "10px", whiteSpace: "pre-line" }}>{description}</Typography>
			</ModalDialog>
		</Modal>
	</Card>
}

export default () => {
	const modalities = [
		{
			title: "Cranial Sacral Therapy",
			description: `
				Osteopathic physician John E. Upledger developed this gentle therapy technique.  It is designed to assess and improve the functioning of the craniosacral system which includes the soft tissue and cerebrospinal fluid that surround and protect the central nervous system.

				Practitioners of CST use their hands to apply a light touch of about the weight of a nickel to free up restrictions in the craniosacral system which have caused pain and imbalance within the body. Some of the disorders that respond well to CST include chronic neck and back pain, Autism, emotional disorders, Post-Traumatic Stress Disorder, Temporomandibular Joint problems, Connective Tissue Disorders, and Chronic Fatigue.
			`
		},
		{
			title: "Barbara Brennan School of Healing Techniques",
			description: `
				These techniques are used for assessing, clearing, balancing and restructuring of the human energy field. This work can facilitate healing on the physical, emotional, mental and spiritual levels.  Restructuring of the energy field after any kind of injury or surgery can be extremely supportive in the recovery process.

				The Hara alignment, which is one of my areas of specialty, is a powerful technique that can assist any client in having a deeper knowing and connection with spirit, their life purpose, their own personal power, and their relationship with the earth. 
			`
		},
		{
			title: "Inner Child Work",
			description: `
                During traumatic events like the death of a family member, a divorce, abuse, or an accident, a child can have an emotionally and physically overwhelming experience that never seems to heal. Generally, the adults in their lives are unaware or are unable to provide the support that the child needs at the time. When the child becomes an adult, that experience continues to play out in their adult lives and in their relationships with others. In many of my sessions, I teach my clients how to reparent themselves; how to give to themselves the words and feelings of comfort that they did not receive as a child.
            `
		},
		{
			title: "Intuitive Readings",
			description: `
                It is my belief that each of us came into this life with the desire at the deepest level of our being to become the best version of human that is possible for us.  For the most part, the programming that we received from our families and our cultures has prevented that from happening.  In my intuitive readings, I can help a person identify the underlying beliefs and patterns that have created suffering in their lives, and then help correct those beliefs and patterns.  Some of the most common patterns are abandonment, unworthiness, shame, guilt, sacrifice, hatred of self and others,  abuse and trauma.
            `
		},
		{
			title: "Soul Infusion",
			description: `
				This technique that I developed facilitates the awakening and infusion of soul qualities such as joy, peace, love, and hope into the individual’s life force energy.  If someone has never experienced these states of being in their life, it is extremely difficult to generate them for themselves.  The soul quality infused is specific to each client’s longing.
			`
		},
		{
			title: "Soul Fragment Retrieval",
			description: `
				During times of intense trauma and abuse, our spirit can break or even shatter.  Those fragmented pieces become frozen in time and are perpetually experiencing the abuse as though it is happening in real time.  The abuse or trauma frequencies then become our backdrop frequencies and play out in every area of our lives, including our relationships with our partners and children.  These fragmented pieces cannot fully heal until they are cleared of the trauma and then lovingly reintegrated back into the spirit body.
			`
		}
	]
	return <Section id="my-work" title="My Work" backgroundImg='dolphins.jpg'>
		<p>
			I am a Brennan Healing Science Practitioner having graduated from the <b>Barbara Brennan School of Healing</b> in 1998.
			In that four-year program, I learned a wide variety of healing techniques designed to promote wholeness in my clients.
			I have had additional training in <b>CranioSacral therapy</b>, including SomatoEmotional Release (SER), CranioSacral Therapy for Pediatrics, and Advanced CranioSacral Therapy.
			I have also developed several <b>healing techniques of my own</b>. Except for CranioSacral therapy and Soul Retrieval, all of the techniques that I do can be done in a <b>group setting</b>
		</p>
		<Box sx={{ flexGrow: "1", display: "flex", flexWrap: "wrap", flexDirection: "row", justifyContent: "space-evenly" }}>
			{modalities.map(modality => <Modality modality={modality} />)}
		</Box>
	</Section>
}
