import { h } from "preact";
import Box from "@mui/joy/Box"
import Sheet from "@mui/joy/Sheet"
import Link from "@mui/joy/Link"
import { SxProps } from "@mui/joy/styles/types";

import styles from "./Navigation.module.css"

export default () => {
    const sections = [
        { name: "My Work", href: "#my-work" },
        { name: "Healing Services", href: "#healing-services" },
        { name: "Testimonials", href: "#testimonials" },
        { name: "Contact & Payment", href: "#contact-and-payment" }
    ]
    return (
        <nav className={styles.nav}>
            {sections.map(section => <Link sx={{ marginX: "10px", whiteSpace: "nowrap" }} key={section.name} href={section.href}>{section.name}</Link>)}
        </nav>
    )
};