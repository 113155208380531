import { h, Fragment } from 'preact';

import Box from "@mui/joy/Box";
import Navigation from "./Navigation";
import ContactAndPayment from './sections/ContactAndPayment';
import MyWork from './sections/MyWork';
import Services from './sections/Services';
import Summary from "./sections/Summary";
import Testimonials from './sections/Testimonials';


const Home = () => (
	<>
		<Navigation />
		<Summary />
		<MyWork />
		<Services />
		<Testimonials />
		<ContactAndPayment />
		<Box component="footer" sx={{ color: "var(--joy-palette-primary-50)", padding: "10px", textAlign: "center" }}>
			© 2023 JOYCE BROWNING. ALL RIGHTS RESERVED.
		</Box>
	</>
);

export default Home;
