import { h } from 'preact';
import Box from "@mui/joy/Box"
import Card from "@mui/joy/Card"
import CardContent from "@mui/joy/CardContent"
import CardCover from "@mui/joy/CardCover"
import Typography from "@mui/joy/Typography"
import { FC, useMemo } from 'preact/compat';
import { SxProps } from '@mui/joy/styles/types';

interface SectionProps {
    id: string;
    title?: string;
    backgroundImg?: string;
}

const sectionStyles: SxProps = {
    "--Card-radius": "0",
    boxShadow: "none",
    justifyContent: "space-around",
    margin: "0",
    minHeight: "100vh",
    padding: "0",
    position: "relative",
    height: "100%",
}

const Section: FC<SectionProps> = ({ id, title, children, backgroundImg }) => {
    const Title = useMemo(() => {
        return <Box sx={{
            backgroundColor: "var(--joy-palette-primary-400)",
            position: "relative",
            height: "200px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "::before": {
                content: "''",
                backgroundAttachment: "fixed",
                backgroundPosition: "center center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundImage: backgroundImg ? `url('/assets/images/${backgroundImg}')` : "unset",
                filter: " opacity(0.4)",
                width: "100%",
                maxWidth: "1000px",
                position: "absolute",
                height: "200px"
            }
        }}>
            <Typography level='h2' sx={{ textAlign: "center" }}>{title}</Typography>
        </Box>
    }, [title])

    return <Card id={id} sx={sectionStyles}>
        <CardContent sx={{ alignSelf: "center", maxWidth: "1000px", width: "100%" }}>
            {title && Title}
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "whitesmoke",
                flexGrow: "1",
                padding: "10px"
            }}>
                {children}
            </Box>
        </CardContent>
    </Card>
}

export default Section