import { h } from "preact"
import Card from "@mui/joy/Card"
import Box from "@mui/joy/Box"
import Typography from "@mui/joy/Typography"

import Section from "../../Section"

export default () => {
	const testimonials = [
		{
			body: `
				Joyce is an incredible medical intuitive, mentor and teacher. 
				I have found individual sessions and group sessions to be very beneficial. 
				During the past 5 plus years, I have been in a group of 5 that meets monthly. 
				These sessions have been invaluable for navigating through the times we are going through. 
				I highly recommend her.
			`,
			name: "Mary S."
		},
		{
			body: `
				Whether receiving from Joyce at an individual level or in a group setting,
				her beautiful and powerful energy healing work has eased physical and emotional pains,
				frustrations and stuckness allowing me to gain insights and awarenesses toward my own healing. 
				Her teachings are so relevant for this time of consciousness shifts on the planet. She is open to share her knowledge, 
				her personal learnings and wisdom from other teachers, all to help us evolve as spiritual beings having this human experience in current times. 
				Joyce is a gifted healer I feel blessed to work with for many years!
			`,
			name: "Alexa U."
		},
		{
			body: `
				What a wonder! I know it’s not magic, but it sure feels like it when Joyce is able to heal a lifetime of wounds. Her art and skill, 
				coupled with amazing intuition, has been instrumental in my healing journey. 
				Whether it was infertility issues, grief, relationship difficulties, or parenting, 
				Joyce was able to help me identify and heal all of life’s challenges. She is gentle, kind and honest when she interacts with me. 
				I can honestly say that my life would not be the same without all of my sessions with Joyce.
				I can experience life with a rich fullness that I never expected. 
				I am so grateful for her.
			`,
			name: "Sue T."
		},
		{
			body: `
				My experience with Joyce Browning has been outstanding and has exceeded my expectations. 
				Joyce's intuition, curiosity and professional expertise provided me with an integrative healing experience over the years.  
				I love that Joyce can understand a lot of what is going on inside of me without me having to explain anything.  She just knows. 
				I trust her completely. Joyce is amazing!
			`,
			name: "Laura U."
		},
		{
			body: `
				Joyce is a compassionate, present, facilitator of her client's healing process. 
				I have had many healing sessions with Joyce that lead me to some awesome ah ha's. 
				This helped me to then know my next steps in my own healing.  
				She made possible a family session with 11 of my family, 3 generations, family friends and spouses to experience a connecting, 
				clearing and healing experience. For most of them it was a first time experience. All took something away from the session.
			`,
			name: "Kathy E."
		},
		{
			body: `
			I’ve had the good fortune to work with Joyce for 9 years now. I’ve found steady progress in my issues and challenges. As a result, I’m more calm, balanced, and my general sense of well-being has improved significantly.  I find the monthly healing sessions to be very helpful and centering, a reminder of what I know, a chance to slow down and a sprinkling of new ways to bring peace and balance to my life. The audio recordings of the meditations are a part of my daily and weekly routines. I come to look forward to the healing sessions and what they bring me.
			`,
			name: "TY"
		},
		{
			body: `
				For about ten years, Joyce has been my go-to practitioner whenever I have had physical, mental and/or emotional concerns. She communicates in a way that puts me at ease, beginning the healing. Her intuitive abilities, together with one or more of her energy work modalities have always had good results. I have also benefited from her Group Sessions and learned a lot in her multiple workshops and classes.
			`,
			name: "Rob S."
		}
	];

	return <Section id="testimonials" title='Testimonials' backgroundImg='whale.jpg'>
		<Box sx={{ maxHeight: "90vh", overflowY: "auto" }}>
			{testimonials.map(testimonial => (
				<Card variant="outlined" sx={{ margin: "5px", boxShadow: "lg", flexBasis: "400px", flexGrow: "1", display: "inline-block" }}>
					<Typography>{testimonial.body}</Typography>
					<Typography component="i" sx={{ float: "right" }}>{testimonial.name}</Typography>
				</Card>
			))}
		</Box>
	</Section>
}