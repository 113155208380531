import { h } from 'preact';
import { Route, Router } from 'preact-router';
import Home from './components/home';

import { Box, Card } from "@mui/joy"

import '@fontsource/public-sans';
import { CssVarsProvider, extendTheme } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';

// Code-splitting is automated for `routes` directory
import Meditations from '~/routes/meditations';
import Group from '~/routes/group';

import styles from "./App.module.css"
import { useEffect } from 'preact/hooks';

const RedirectToHome = () => {
  useEffect(() => {
    window.location.href = '/';
  }, [])

  return (
    <Card sx={{ height: "90vh", width: "90vh", margin: "5vh 5vw" }}>
      <h1>Page Not Found</h1>
      <h2>Redirecting to homepage...</h2>
    </Card>
  )
}



const customTheme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        background: {
          surface: "rgba(255, 255, 255, 0.8)",
        },
        primary: {
          50: "#d3b7c6",
          100: "#d3b7c6",
          200: "#d3b7c6",
          300: "#d3b7c6",
          400: "#d3b7c6",
          500: "#624d5e",
          600: "#624d5e",
          700: "#624d5e",
          800: "#624d5e",
          900: "#624d5e",
          softBg: "#624d5e",
          softColor: "#e5e3dd",
        },
        neutral: {

        }
      }
    },
  },
});

const App = () => (
  <CssVarsProvider theme={customTheme}>
    <CssBaseline />

    <Box id="app" className={styles.root}>
      <Router>
        <Route path="/" component={Home} />
        <Route path="/group" component={Group} />
        <Route path="/meditations" component={Meditations} />
        <Route default component={RedirectToHome} />
      </Router>
    </Box>
  </CssVarsProvider>
);

export default App;
