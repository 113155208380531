import { h } from "preact"
import Box from "@mui/joy/Box"
import Card from "@mui/joy/Card"
import Link from "@mui/joy/Link"
import Typography from "@mui/joy/Typography"

import { CallButton, EmailButton } from "../common"
import Section from "../../Section"

export default () => {
	return <Section id="contact-and-payment" title="Contact & Payment" backgroundImg='flowers4.jpg'>
		<Card variant="outlined" sx={{ flexGrow: "1", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", padding: "40px", textAlign: "center" }}>
			<Typography level="h4" sx={{ marginBottom: "10px" }}>Please contact me to schedule a healing session or to register for any of the offered services.</Typography>
			<Typography>You can reach me by phone</Typography>
			<br />
			<CallButton size="sm" />
			<br />
			<Typography sx={{ marginTop: "5px" }}>or email me</Typography>
			<br />
			<EmailButton size="sm" />
			<br />
		</Card>
		<Card variant="outlined" sx={{ flexGrow: "1", alignItems: "center", display: "flex", flexDirection: "column", padding: "40px", justifyContent: "space-around", marginTop: "10px" }}>
			<Typography level="h4">I accept the following methods of payment</Typography>
			<Box>
				<Link href="https://www.zellepay.com/" target="_blank">
					<Box component="img" sx={{ borderRadius: "10px", height: "50px" }} src="/assets/images/external/zelle.jpg" />
				</Link>
			</Box>
			<Box>
				<form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
					<input type="hidden" name="cmd" value="_s-xclick" />
					<input type="hidden" name="hosted_button_id" value="NMXH5Q7BBV3MU" />
					<Box component="input" type="image" class="session" name="submit" sx={{ borderRadius: "10px", height: "50px" }} src="/assets/images/external/paypal.png" />
				</form>
			</Box>

			<Typography>Please contact me for more information on how to complete payment</Typography>
		</Card>
	</Section>
}