import { h } from "preact"
import Card from "@mui/joy/Card"
import Box from "@mui/joy/Box"
import Typography from "@mui/joy/Typography"

import Section from "../../Section"

export default () => {
	const services = [
		{
			title: "Individual Sessions",
			description: "I do individual one-hour online sessions that are specific to the needs of the client.  The cost is $150. "
		},
		{
			title: "Group Healings for Adults",
			description: "I do 1 ½ hour online Zoom group sessions each month that have a specific theme. Themes have included Creating Safety as a State of Being, Recovering from Physical and Sexual Trauma, Healing the Divine Feminine, Healing Family Lineage, Clearing Frequencies of Abandonment, Anchoring into Your Power and many more. Each session includes a teaching portion which is followed by a healing meditation. The meditations are recorded and each participant receives a copy of the recording.  The cost is $120.  If you would like to be added to my mailing list, please send me your contact information and you will receive notification of upcoming group sessions."
		},
		{
			title: "Group Healings for Children",
			description: "I worked for many years as a Pediatric Speech Pathologist before moving into my healing practice.  I have a passion for helping children navigate their ways through these challenging times.  I am offering monthly Group Healings for children from the age of birth to 18 years.  The focus of these healings is to clear the children of the distortions such as fear and anxiety they are picking up from their families and from our communities.  The cost is $30 per child.  Please contact me if you would like to be added to my mailing list for these events.  The work is done long distance in the evening Central Time."
		}
	]

	return <Section id="healing-services" title="Healing Services" backgroundImg='butterfly.png'>
		<Box sx={{ flexGrow: "1", display: "flex", flexDirection: "col", flexWrap: "wrap", justifyContent: "space-around" }}>
			{services.map(({ title, description }) => (
				<Card variant="outlined" sx={{ margin: "3px", width: "100%" }}>
					<Typography level='h3'>{title}</Typography>
					<Typography>{description}</Typography>
				</Card>
			))}
		</Box>
	</Section>
}