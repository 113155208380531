import { h } from 'preact';
import { FC, PropsWithChildren } from 'preact/compat';

import Button, { ButtonProps } from "@mui/joy/Button"
import Link from "@mui/joy/Link"

import Call from '@mui/icons-material/Call'
import Email from '@mui/icons-material/Email'

import styles from "./common.module.css"



interface IconButtonProps extends PropsWithChildren {
	icon?: ButtonProps["startDecorator"],
	size?: ButtonProps["size"]
}

export const IconButton: FC<IconButtonProps> = ({ children, icon, size = "md" }) => (
	<Button variant='plain' size={size} startDecorator={icon}>
		{children}
	</Button>
)

interface WrappedButtonProps {
	size?: ButtonProps["size"]
}

export const CallButton: FC<WrappedButtonProps> = ({ size = "md" }) => (
	<Link href="tel:6514423394">
		<IconButton size={size} icon={<Call />}>
			(651) 442-3394
		</IconButton>
	</Link>
)

export const EmailButton: FC<WrappedButtonProps> = ({ size = "md" }) => (
	<Link href="mailto:joyceebrowning@gmail.com">
		<IconButton size={size} icon={<Email />}>
			joyceebrowning@gmail.com
		</IconButton>
	</Link>
)
