import { h } from "preact"
import Box from "@mui/joy/Box"
import Card from "@mui/joy/Card"
import CardContent from "@mui/joy/CardContent"
import CardCover from "@mui/joy/CardCover"
import Sheet from "@mui/joy/Sheet"
import Typography from "@mui/joy/Typography"

import { CallButton, EmailButton } from "../common"
import Section from "../../Section"

import styles from "./Summary.module.css"

export default () => {
    return <Section id="summary">
        <CardCover className={styles.cover}>
            <img loading="eager" src="/assets/images/summary_flowers.webp" alt="field of flowers" />
        </CardCover>
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: "1",
            gap: "5px",
            justifyContent: "space-around",
            textAlign: "center",
            fontWeight: "bolder",
            zIndex: "0"
        }}>
            <Box sx={{ borderRadius: "10px", fontSize: "1.3rem", flexGrow: "2", display: "flex", flexDirection: "column", justifyContent: "center", padding: "5px" }}>
                <Typography level="h1">Joyce Browning</Typography>
                <h2 className={styles.subtitle}>- Healing Science Practitioner -</h2>
            </Box>
            <Card sx={{
                alignItems: "center",
                boxShadow: "lg",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "center",
                margin: "auto",
                maxWidth: "100%",
                width: "1000px",
            }}>
                <Box component="img" height="30vh" alt="Portrait picture of Joyce Browning" sx={{
                    minHeight: "300px",
                    maxHeight: "400px",
                    margin: "30px",
                    border: "1px solid white",
                    borderRadius: "30px",
                    boxShadow: "lg"
                }} src="/assets/images/joyce.webp" />
                <Box sx={{
                    flexGrow: "1",
                    minWidth: "50%",
                    width: "min-content",
                }}>
                    <Typography color="primary" sx={{ fontSize: "lg", fontStyle: "italic", fontWeight: "xl" }}>
                        It is my belief that we are first and foremost spiritual beings having a human experience.
                    </Typography>
                    <br />
                    <Typography>
                        We are here on this planet to wake up fully in our bodies to that deeper truth.
                    </Typography>
                    <br />
                    <Typography>
                        Everything that we do, be and experience can bring us into a deeper alignment with that knowing.
                    </Typography>
                    <br />
                    <Typography>
                        Over the last 40 years, I have studied and developed healing practices that would bring me into that alignment and help others to do the same.
                    </Typography>
                </Box>
                <div>
                    <CallButton />
                    <EmailButton />
                </div>
            </Card>
            <Box sx={{ flexGrow: "2" }} />
        </Box>
    </Section>
}
